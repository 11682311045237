import Modal from './components/modal'
const sitenavToggle = document.querySelector('.sitenav-toggle')
const mobileMenu = sitenavToggle.closest('.mobile-sitenav')

if (sitenavToggle && mobileMenu) {
  sitenavToggle.addEventListener('click', event => {
    const startState = mobileMenu.dataset.state
    const endState = startState === 'open' ? 'closed' : 'open'

    mobileMenu.dataset.state = endState
  })
}

const modal = new Modal({
  triggerElement: document.querySelector('.newsletter-modal-trigger'),
  modalElement: document.querySelector('.newsletter-modal')
})

console.log(modal)
