import mix from '@zellwk/javascript/mix'

const defaults = {
  triggerElement: '',
  modalElement: '',
  type: 'normal', // normal, timed
  delayBeforeOpening: 0,
  afterOpen () {}
}

export default function Modal (settings) {
  settings = Object.assign({}, defaults, settings)

  const { type, modalElement, triggerElement } = settings
  const overlayElement = modalElement.parentElement
  const closeButtonElement = modalElement.querySelector(
    '[data-js="close-button"]'
  )

  settings.overlayElement = overlayElement

  // Initial Checks
  if (!triggerElement) {
    throw new Error('triggerElement is required')
  }

  if (!modalElement) {
    throw new Error('modalElement is required')
  }

  if (!overlayElement) {
    throw new Error('Please add an overlay wrapper to the modal')
  }

  if (!closeButtonElement) {
    throw new Error(
      'Please add close button to the modal. This button should have \'data-js="close-button"\' as an attribute. '
    )
  }

  // Adjusts Modal Element Position to a direct child of body. This is necessary for the modal to be positioned correctly
  if (overlayElement.parentElement !== document.body) {
    document.body.append(overlayElement)
  }

  // Create Modal
  let modal
  switch (type) {
    case 'normal':
      modal = NormalModal(settings)
      break
    case 'timed':
      modal = TimedModal(settings)
      break
  }

  // Adds event listeners

  closeButtonElement.addEventListener('click', event => {
    modal.close()
  })

  overlayElement.addEventListener('click', event => {
    if (!event.target.closest('.modal')) {
      modal.close()
    }
  })

  document.addEventListener('keydown', event => {
    if (modal.isOpen && event.key === 'Escape') {
      modal.close()
    }
  })

  return modal
}

function BaseModal (settings) {
  const { modalElement, overlayElement } = settings
  const contentElement = modalElement.querySelector('.modal__content')

  function trapFocus (event) {
    const focusables = getKeyboardFocusableElements(modalElement)
    const firstFocusable = focusables[0]
    const lastFocusable = focusables[focusables.length - 1]

    // Directs to first focusable
    if (
      document.activeElement === lastFocusable &&
      event.key === 'Tab' &&
      !event.shiftKey
    ) {
      event.preventDefault()
      firstFocusable.focus()
    }

    // Directs to last focusable
    if (
      document.activeElement === firstFocusable &&
      event.key === 'Tab' &&
      event.shiftKey
    ) {
      event.preventDefault()
      lastFocusable.focus()
    }
  }

  const modal = {
    modalElement,
    contentElement,
    overlayElement,

    get isOpen () {
      return overlayElement.classList.contains('is-open')
    },

    get siblingElements () {
      return [...overlayElement.parentElement.children].filter(
        el => el !== overlayElement
      )
    },

    showSiblingElements () {
      modal.siblingElements.forEach(element => {
        element.removeAttribute('aria-hidden')
      })
    },

    hideSiblingElements () {
      modal.siblingElements.forEach(element => {
        element.setAttribute('aria-hidden', true)
      })
    },

    open () {
      overlayElement.classList.add('is-open')
      document.body.style.overflow = 'hidden'
      document.addEventListener('keydown', trapFocus)
      modal.hideSiblingElements()

      // Autofocus
      const focusableEls = getKeyboardFocusableElements(contentElement)
      if (focusableEls[0]) focusableEls[0].focus()

      settings.afterOpen()
    },

    close () {
      overlayElement.classList.remove('is-open')
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', trapFocus)
      modal.showSiblingElements()
    }
  }

  return modal
}

function NormalModal (settings) {
  const { triggerElement } = settings
  const base = BaseModal(settings)

  const modal = mix(base, {
    open () {
      base.open()
      triggerElement.setAttribute('aria-expanded', true)
    },

    close () {
      base.close()
      triggerElement.setAttribute('aria-expanded', false)
      triggerElement.focus()
    }
  })

  triggerElement.addEventListener('click', event => {
    modal.open()
  })

  return modal
}

function TimedModal (settings) {
  const modal = BaseModal(settings)
  setTimeout(_ => modal.open(), settings.delayBeforeOpening)

  return modal
}

function getKeyboardFocusableElements (element = document) {
  return [
    ...element.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]'
    )
  ]
    .filter(el => !el.hasAttribute('disabled'))
    .filter(
      el => !el.hasAttribute('tabindex') || el.getAttribute('tabindex') >= 0
    )
}
